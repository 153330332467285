
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

interface Filter {
  credit_number: string;
  order_number: string;
  status: string;
  credit_date_start: string;
  credit_date_end: string;
}

export default defineComponent({
  name: "filter-credit-note-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const formData = ref<Filter>({
      credit_number: "",
      order_number: "",
      status: "",
      credit_date_start: "",
      credit_date_end: "",
    });

    const options = ref({
      status: [
        {
          label: t("salesOrder.orderStatusPending"),
          value: "pending",
        },
        {
          label: t("salesOrder.orderStatusPendingPayment"),
          value: "pending_payment",
        },
        {
          label: t("salesOrder.orderStatusSellOnCredit"),
          value: "sell_on_credit",
        },
        {
          label: t("salesOrder.orderStatusProcessing"),
          value: "processing",
        },
        {
          label: t("salesOrder.orderStatusPartialShipped"),
          value: "partial_shipped",
        },
        {
          label: t("salesOrder.orderStatusShipped"),
          value: "shipped",
        },
        {
          label: t("salesOrder.orderStatusComplete"),
          value: "complete",
        },
        {
          label: t("salesOrder.orderStatusRefunded"),
          value: "refunded",
        },
        {
          label: t("salesOrder.orderStatusCanceled"),
          value: "canceled",
        },
        {
          label: t("salesOrder.orderStatusHoldOn"),
          value: "hold_on",
        },
      ],
    });

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    return {
      t,
      formData,
      formRef,
      submit,
      handleReset,
      options,
    };
  },
});
