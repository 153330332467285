
import { defineComponent, ref, onMounted, onActivated } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import FilterDropdown from "@/views/documents/credit-note/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiCreditNote } from "@/core/api";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { FilterOption, SortOption } from "@/core/directive/interface/common";
import { CreditNoteTable } from "@/core/directive/interface/creditNote";
import store from "@/store";
import { useRoute } from "vue-router";
import {
  commonChangeFilterAddDate,
  commonExportFile,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "documents-credit-note",
  components: {
    MBDatatable,
    FilterDropdown,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<CreditNoteTable>>([]);
    const checkedCompanys = ref([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(
      store.getters.currentConfigPageSize(route.name)
    );
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([]);
    const sortOptions = ref<Array<SortOption>>([]);
    let childWin: Window | null;
    const tableHeader = ref([
      {
        name: t("creditNote.creditNumber"),
        key: "credit_number",
        sortable: true,
      },
      {
        name: t("creditNote.creditDate"),
        key: "credit_date",
        sortable: true,
      },
      {
        name: t("creditNote.orderNumber"),
        key: "order_number",
        sortable: false,
      },
      {
        name: t("creditNote.orderDate"),
        key: "order_date",
        sortable: true,
      },
      {
        name: t("creditNote.billToName"),
        key: "bill_to",
        sortable: false,
      },
      {
        name: t("creditNote.shipToName"),
        key: "ship_to",
        sortable: false,
      },
      {
        name: t("creditNote.refunded"),
        key: "refunded",
        sortable: false,
        align: "right",
      },
      {
        name: t("creditNote.status"),
        key: "status",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const init = () => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getSalesInvoiceList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    const getSalesInvoiceList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiCreditNote.getSalesCreditList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            tableData.value.forEach((item) => {
              item.downloadDisabled = false;
              item.showDisabled = false;
            });
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateList = () => {
      getSalesInvoiceList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getSalesInvoiceList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getSalesInvoiceList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (item == "credit_date_start") {
            filterArr.push({
              field: item,
              value: commonChangeFilterAddDate(val[item], 1),
              condition: "gteq",
            });
          } else if (item == "credit_date_end") {
            filterArr.push({
              field: item,
              value: commonChangeFilterAddDate(val[item]),
              condition: "lteq",
            });
          } else if (item == "status") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getSalesInvoiceList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getSalesInvoiceList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      // let sts = {};
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getSalesInvoiceList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const orderStatus = (status) => {
      let str = "",
        statusClass = "";
      switch (status) {
        case "Pending":
        case "pending":
        case "New":
        case "new":
          str = t("salesOrder.orderStatusPending");
          statusClass = "badge-light-primary"; //蓝
          break;
        case "Pending Payment":
        case "pending_payment":
          str = t("salesOrder.orderStatusPendingPayment");
          statusClass = "badge-light-primary"; //蓝
          break;
        case "Sell On Credit":
        case "sell_on_credit":
          str = t("salesOrder.orderStatusSellOnCredit");
          statusClass = "badge-light-primary"; //蓝
          break;
        case "Processing":
        case "processing":
          str = t("salesOrder.orderStatusProcessing");
          statusClass = "badge-light-warning"; // 黄
          break;
        case "Partial Shipped":
        case "partial_shipped":
          str = t("salesOrder.orderStatusPartialShipped");
          statusClass = "badge-light-info"; // 紫
          break;
        case "Shipped":
        case "shipped":
          str = t("salesOrder.orderStatusShipped");
          statusClass = "badge-light-info"; // 紫
          break;
        case "Complete":
        case "complete":
          str = t("salesOrder.orderStatusComplete");
          statusClass = "badge-light-success"; // 绿
          break;
        case "Refunded":
        case "refunded":
          str = t("salesOrder.orderStatusRefunded");
          statusClass = "badge-light-danger"; // 红
          break;
        case "Canceled":
        case "canceled":
          str = t("salesOrder.orderStatusCanceled");
          statusClass = "badge-light-dark"; // 黑
          break;
        case "Hold On":
        case "On Hold":
        case "hold_on":
        case "holded":
          str = t("salesOrder.orderStatusHoldOn");
          statusClass = "badge-light-danger"; // 红
          break;
        default:
          statusClass = "badge-light-primary"; //蓝
      }
      return { str, statusClass };
    };

    const fileExport = async (id, item) => {
      item.downloadDisabled = true;
      const data = await ApiCreditNote.print({
        id: id,
        download_type: "stream",
      });
      item.downloadDisabled = false;
      commonExportFile(data);
    };

    const fileShow = (id, item) => {
      item.showDisabled = true;
      ApiCreditNote.print2({
        id: id,
        download_type: "url",
      })
        .then(({ data }) => {
          item.showDisabled = false;
          childWin = window.open(
            data.data.url,
            "newwindow",
            "height=700, width=600, top=10, left=10, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
          );
          childWin!.onload = function () {
            // childWin.print();
          };
        })
        .catch((error) => {
          item.showDisabled = false;
          console.log(error);
        });
    };

    return {
      t,
      formatDateTime,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      checkedCompanys,
      getSalesInvoiceList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      removeSearch,
      handleFilter,
      handleFilterReset,
      updateList,
      orderStatus,
      fileExport,
      fileShow,
    };
  },
});
